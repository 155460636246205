import * as React from 'react'
import { compose, get, map } from 'lodash/fp'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { MdArrowForward, MdErrorOutline } from 'react-icons/md'
import { toast } from 'react-toastify'

import Layout from 'src/components/Layouts'
import { localize } from 'src/components/Localize'
import { isDefaultLanguage } from '@/components/Language'
import { Form, CheckoutInput, Errors, ErrorSign, ErrorMsg } from '@/components/shared/FormElements'
import Container from 'src/components/Container'
import Spinner from '@/components/Spinner'
import SEO from 'src/components/SEO'
import Modules from 'src/components/Modules'
import Button from '@/components/Button'
import mediaqueries from '@/styles/media'

const validationSchema = Yup.object({
  fullName: Yup.string().required(
    isDefaultLanguage() ? 'İsim ve Soy isim gerekli ' : 'Navn påkreves'
  ),
  subject: Yup.string().required(isDefaultLanguage() ? 'Konu gerekli ' : 'Subjeckt påkreves'),
  message: Yup.string().required(isDefaultLanguage() ? 'Mesaj gerekli ' : 'Subjeckt påkreves'),
  email: Yup.string()
    .trim()
    .email(
      isDefaultLanguage()
        ? 'E-posta, geçerli bir e-posta olmalıdır '
        : 'E-post må være en gyldig e-post'
    )
    .required(isDefaultLanguage() ? 'E-posta gereklidir ' : 'E-post er påkrevd'),
})

// Transforms the form data from the React Hook Form output to a format Netlify can read
const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const Contact = ({ path, pageContext, preview = false, data }) => {
  const {
    main: { modules, title, slug },
    siteGlobal,
    meta,
    locale,
  } = pageContext

  const menuItems = compose(map('node'), get('allSanityMenus.edges'))(data)
  const imageModule = modules.find((module) => module._type === 'imageModule')
  const standardText = modules.find((module) => module._type === 'standardText')

  const [isLoading, setIsLoading] = React.useState(false)

  const navMenuItems = menuItems.map((menuItem) => ({
    ...menuItem,
    items: menuItem.items.map((item) => ({
      ...item,
      link: get('link.content.main.slug.current', item),
    })),
  }))

  const initialValues = {
    fullName: '',
    email: '',
    subject: '',
    message: '',
  }

  const { handleSubmit, handleChange, values, errors, isValid, dirty, resetForm } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      handleFormSubmit(values)
    },
  })

  const handleFormSubmit = async (values) => {
    setIsLoading(true)

    const data = { ...values, 'form-name': 'contact-form' }
    const successMessage =
      locale === 'tr'
        ? 'Mesajınız gönderilmiştir. Teşekkür ederiz'
        : 'Din melding har blitt sendt. Vi takker deg!'

    await fetch(`${locale === 'tr' ? `${path}` : `${locale}${path}`}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode(data),
    })
      .then((response) => {
        toast.success(successMessage, {
          position: toast.POSITION.TOP_CENTER,
        })
        console.log('response', response)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setIsLoading(false)
        resetForm({ values: '' })
      })
  }

  return (
    <Layout navMenuItems={navMenuItems} siteGlobal={siteGlobal} locale={locale}>
      <SEO title={title} metaInfo={meta} pagePath={`/${locale}${path}`} />
      <Modules type={imageModule._type} reactModule={imageModule} locale={locale} />
      <Container paddingVertical={80}>
        <Grid>
          <Modules type={standardText._type} reactModule={standardText} locale={locale} />
          <Form
            name="contact-form"
            method="POST"
            data-netlify="true"
            netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="form-name" value="contact-form" />
            <div>
              <CheckoutInput
                id="fullName"
                name="fullName"
                onChange={handleChange}
                type="text"
                value={values.fullName}
                placeholder={isDefaultLanguage() ? 'İsim Soyisim *' : 'Navn etternavn *'}
              />
              <Errors show={errors.fullName}>
                <ErrorSign>
                  <MdErrorOutline />
                </ErrorSign>
                <ErrorMsg>{errors.fullName ? errors.fullName : null} </ErrorMsg>
              </Errors>
            </div>

            <div>
              <CheckoutInput
                id="subject"
                name="subject"
                onChange={handleChange}
                type="text"
                value={values.subject}
                placeholder={isDefaultLanguage() ? 'Konu *' : 'Emne *'}
              />
              <Errors show={errors.subject}>
                <ErrorSign>
                  <MdErrorOutline />
                </ErrorSign>
                <ErrorMsg>{errors.subject ? errors.subject : null} </ErrorMsg>
              </Errors>
            </div>

            <div>
              <CheckoutInput
                id="email"
                name="email"
                onChange={handleChange}
                type="text"
                value={values.email}
                placeholder={isDefaultLanguage() ? 'E-posta *' : 'E-post *'}
              />
              <Errors show={errors.email}>
                <ErrorSign>
                  <MdErrorOutline />
                </ErrorSign>
                <ErrorMsg>{errors.email ? errors.email : null} </ErrorMsg>
              </Errors>
            </div>

            <div>
              <TextArea
                id="message"
                name="message"
                onChange={handleChange}
                type="text"
                rows="6"
                value={values.message}
                placeholder={isDefaultLanguage() ? 'Mesaj *' : 'Beskjed *'}
              ></TextArea>
              <Errors show={errors.message}>
                <ErrorSign>
                  <MdErrorOutline />
                </ErrorSign>
                <ErrorMsg>{errors.message ? errors.message : null} </ErrorMsg>
              </Errors>
            </div>

            <Button
              css={css`
                font-size: 15.2px;
              `}
              type="submit"
              disabled={!(isValid && dirty) || isLoading}
            >
              {isLoading ? (
                <Spinner />
              ) : isDefaultLanguage() ? (
                <>
                  Gönder <MdArrowForward />
                </>
              ) : (
                <>
                  Sende <MdArrowForward />
                </>
              )}
            </Button>
          </Form>
        </Grid>
      </Container>
    </Layout>
  )
}

export default localize(Contact)

const Grid = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3.5rem;
  padding: 0 10px 50px;

  ${mediaqueries.phablet`
    grid-template-columns: 1fr;
  `};

  & > section > div {
    padding-top: 0;

    p {
      margin-bottom: 0;
    }

    a {
      color: ${(p) => p.theme.colors.articleText};
    }
  }
`

const TextArea = styled.textarea`
  background: transparent;
  border: 1px solid #ebebeb;
  height: auto;
  resize: none;
  max-width: 100%;
  width: 100%;
  padding: 10px 15px;
  font-size: 13px;
  line-height: 1.45;
  margin: 15px 0;
`

export const query = graphql`
  query ContactTemplateQuery {
    allSanitySiteGlobal {
      edges {
        node {
          _rawContent
        }
      }
    }
    allSanityMenus {
      edges {
        node {
          menuLocation {
            mainMenu
            footerMenu
          }
          items {
            ... on SanityInternalLink {
              title {
                no
                tr
                _type
              }
              link {
                content {
                  main {
                    slug {
                      _type
                      tr {
                        current
                        _type
                      }
                      no {
                        current
                        _type
                      }
                    }
                  }
                }
              }
            }
          }
          title {
            no
            tr
            _type
          }
          slug {
            _type
            tr {
              current
              _type
            }
            no {
              current
              _type
            }
          }
        }
      }
    }
  }
`
